import Vue from 'vue'
import Router from 'vue-router'
import index from '../components/index.vue'


Vue.use(Router)

const router = new Router({

  routes : [
    { path:'/', redirect: '/index' },
    { path:'/index', component: index },
  ]
  
})

export default router
