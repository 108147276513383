<template>
<div style="position:absolute;left:0;top: 0;bottom: 0;right: 0;margin: auto;height:80px;width:250px;text-align:center;">
  <el-upload
    action="#"
    accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf"
    :limit="1"
    :before-upload="upload"
    v-loading="is_loading"
  >
    <el-button type="primary" style="background-color:#D85050;border:0;border-radius:10px;font-size:18px;">点击上传</el-button>
  </el-upload>
</div>
</template>

<script>
export default {
  data() {
    return{
        file_url: "",
        is_loading:false
    }
  },
  methods: {
    upload(file) {
      console.log(file);
      var that = this;
      that.is_loading = true;
      var COS = require("../assets/cos-js-sdk-v5.js");
      var Bucket = "boatonland-1307992092";
      var Region = "ap-beijing";
      var cos = new COS({
        ForcePathStyle: true, // 如果使用了很多存储桶，可以通过打开后缀式，减少配置白名单域名数量，请求时会用地域域名
        getAuthorization(options, callback) {
          var url =
            "https://image.boatonland.com/index.php?bucket=" +
            Bucket +
            "&region=" +
            Region; // url替换成您自己的后端服务
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onload = function (e) {
            try {
              var data = JSON.parse(e.target.responseText);
              var credentials = data.credentials;
            } catch (e) {}
            if (!data || !credentials) {
              return console.error(
                "credentials invalid:\n" + JSON.stringify(data, null, 2)
              );
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          };
          xhr.send();
        },
      });

      cos.putObject(
        {
          Bucket: Bucket,
          Region: Region,
          Key: that.randomString() + that.getExt(file.name),
          StorageClass: "STANDARD",
          Body: file,
          onProgress: function (info) {
            console.log(info);
            console.log(JSON.stringify(info));
          },
        },
        function (err, data) {
          console.log(err || data);
          if (data.Location) {
            var location =
              "https://i.boatonland.com/" +
              data.Location.substr(data.Location.lastIndexOf("/") + 1);
            console.log(location);
            that.file_url = location;
            that.is_loading = false;
            wx.miniProgram.postMessage({ data: that.file_url });
            wx.miniProgram.navigateBack();
          }
        }
      );
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    getExt(filename){
      var idx = filename.lastIndexOf('.');
      return (idx < 1) ? "" : "." + filename.substr(idx + 1);
    },
  },
};
</script>